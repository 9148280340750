<div class="footer" [ngClass]="{'footer-dark':darkModeService.getDarkMode()}">
  <div class="logo">
    <img src="../../../assets/images/lnr.svg" />
  </div>
  <span>Dijets Improvement Proposals</span>
  <div class="icons">
    <span>
      <a class="social" href="https://forum.dijets.io/" target="_blank">
      <img class="forum" src="../../../assets/images/forum_white.svg" />
      Forum
      </a>      
    </span>
    <span>
      <a class="social" href="https://vote.dijets.io/" target="_blank">
      <img class="vote" src="../../../assets/images/voters-dark.svg" />
      Voting
      </a>      
    </span>
  </div>
</div>
