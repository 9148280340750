<img
  *ngIf="!darkModeService.getDarkMode()"
  class="mobile"
  src="../../../../../assets/images/404-page-not-found-mobile.svg"
/>
<img
  *ngIf="!darkModeService.getDarkMode()"
  class="no-mobile"
  src="../../../../../assets/images/404-page-not-found.svg"
/>
<img
  *ngIf="darkModeService.getDarkMode()"
  class="background no-mobile"
  src="../../../../../assets/images/404-page-not-found-dark.png"
/>
<img
  *ngIf="darkModeService.getDarkMode()"
  class="background mobile"
  src="../../../../../assets/images/404-page-not-found-dark-mobile.png"
/>
<div class="center-card" [ngClass]="{'card-center-dark':darkModeService.getDarkMode()}">
  <h1>OOPS!</h1>
  <p>{{ "The page you requested could not be found" }}</p>
  <button mat-button (click)="goHome()">{{ "Back to Home" }}</button>
</div>
